* {
  box-sizing: border-box;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #808080cc;
}

::-webkit-scrollbar-thumb:hover {
  background: #888888ff;
}

::-webkit-scrollbar-track {
  background: #d6d6d611;
}
::-webkit-scrollbar-track:hover {
  background: #d6d6d622;
}

// @font-face {
//   font-family: 'RedHatDisplay-Bold';
//   src: url(/fonts/RedHatDisplay-Bold.ttf) format('truetype');
// }

// @font-face {
//   font-family: 'RedHatDisplay-Regular';
//   src: url(/fonts/RedHatDisplay-Regular.ttf) format('truetype');
// }

// @font-face {
//   font-family: 'RedHatDisplay-Medium';
//   src: url(/fonts/RedHatDisplay-Medium.ttf) format('truetype');
// }

@font-face {
  font-family: 'Atkinson-Hyperlegible-Regular';
  src: url(/fonts/Atkinson-Hyperlegible-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DX-Rigraf';
  src: url(/fonts/DXRigraf-SemiBold.otf) format('opentype');
}

a,
a:visited,
a:active {
  color: inherit;
  opacity: 0.75;
  text-decoration: none;
}

a:hover {
  opacity: 1;
}

a.activeNav {
  opacity: 1;
  // text-decoration: underline;
  // text-decoration-thickness: 2px;
  // text-underline-offset: 35%;
}
a.activeNavDashed {
  opacity: 1;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 2px;
  text-underline-offset: 35%;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  color: #d6d6d6;
  overflow: hidden;
}

body {
  font-family: 'Atkinson-Hyperlegible-Regular';
  background-color: #d6d6d6;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: -1;
}

.scrollDiv {
  top: 0;
  left: 0;
  width: 100vw;
  overflow-y: hidden;
}

.canvasContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 90vw;
  height: 100vh;
  overflow: hidden;
}

#headerRoot {
  padding-bottom: 120px;
  // margin-top: 0px;
  pointer-events: none;
  opacity: 1;
}

.headerBackground {
  // background-color: red;
  pointer-events: none;
  top: 1px;
  left: 0;
  right: 0;
  height: 120px;
  position: fixed;
  -webkit-mask: -webkit-linear-gradient(black 30%, transparent 100%);
  backdrop-filter: blur(15px);
  z-index: -100;
}

header {
  // mix-blend-mode: difference;
  z-index: 50;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  opacity: 0.95;
  font-size: 22px;
  letter-spacing: 1px;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  padding: 30px 20px 0px 45px;

  // font-variant-ligatures: common-ligatures discretionary-ligatures contextual;
  font-feature-settings: 'ss02';

  .header-inner {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'DX-Rigraf';
    .logo {
      pointer-events: auto;
      display: flex;
      font-size: 25px;
      min-width: 280px;
      letter-spacing: 5px;
      justify-content: center;
    }
    .nav {
      display: flex;
      // flex-grow: 0.3;
      justify-content: space-around;
      .item {
        pointer-events: auto;
        text-align: left;
        width: 280px;
        // margin: 0 35px;
      }
    }
    .socialBar {
      min-width: 275px;
      display: flex;
      justify-content: flex-end;
      margin-right: 15px;
      .icon-bar {
        pointer-events: auto;
        margin: 0 15px;
      }
    }
  }
  .header-inner.active {
    transition: all 0.5s ease;
    position: fixed;
    left: 0;
    top: 0;
    color: inherit;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-evenly;
    .logo {
      pointer-events: auto;
      display: flex;
      letter-spacing: 5px;
      justify-content: center;
    }
    .nav {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .item {
        pointer-events: auto;
        margin: 0 35px;
      }
    }
    .socialBar {
      display: flex;
      justify-content: center;
      margin: 0 -20px;
      .icon-bar {
        pointer-events: auto;
        margin: 0 15px;
      }
    }
  }
  .mobileBar {
    pointer-events: auto;
    z-index: 999;
    display: none;
  }
  .threeCredit {
    z-index: -1;
    font-size: 26px;
    position: fixed;
    bottom: 50px;
    left: 75px;
    width: 100px;
    display: flex;
    direction: row;
    opacity: 0.4;
  }
  .threeCredit:hover {
    opacity: 1;
    cursor: none;
  }
  .threeCredit:hover .threeText {
    opacity: 1;
  }
  .threeText {
    opacity: 0;
  }
  .fullscreenButton {
    pointer-events: auto;
    z-index: -1;
    position: fixed;
    bottom: 40px;
    right: 40px;
    font-size: 36px;
    opacity: 0.5;
  }
  .fullscreenButton:hover {
    opacity: 1;
    cursor: pointer;
  }
}

footer {
  z-index: 50;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  padding: 50px 75px 0 75px;
  background-color: #202020;
  box-shadow: 0px 50px 50px 25px #202020;
  opacity: 0.9;
}

.title {
  font-size: 10rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  span {
    display: block;
  }
}

@media (max-width: 1200px) {
  ::-webkit-scrollbar-track {
    background: #d6d6d600;
  }

  header {
    right: 0px;
    padding: 50px 75px 0px 75px;
    .header-inner {
      .nav {
        display: none;
      }
      .socialBar {
        display: none;
      }
    }
    .mobileBar {
      display: block;
      flex-direction: column-reverse;
      color: inherit;
    }
    .fullscreenButton {
      z-index: -1;
      position: fixed;
      bottom: 75px;
      right: 75px;
      font-size: 30px;
      opacity: 0.5;
    }
    .fullscreenButton:hover {
      opacity: 0.5;
    }
  }
}

@media (max-width: 720px) {
  /* width */
  ::-webkit-scrollbar {
    width: unset;
  }

  ::-webkit-scrollbar-track {
    background: #d6d6d600;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: unset;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: unset;
  }

  a,
  a:visited,
  a:active {
    opacity: 1;
  }

  header {
    right: 0px;
    padding: 30px 30px 60px 30px;
    font-size: 18px;

    .socialBar {
      font-size: 32px;
      height: 70%;
      flex-direction: column-reverse;
      align-items: center;
      .icon-bar {
        padding: 15px;
      }
    }

    .fullscreenButton {
      bottom: 25px;
      right: 25px;
      font-size: 25px;
    }
  }

  .headerBackground {
    height: 250px;
  }
}
